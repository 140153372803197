export {default as IconAppStore} from './appstore';
export {default as IconBookmark} from './bookmark';
export {default as IconCodepen} from './codepen';
export {default as IconExternal} from './external';
export {default as IconFolder} from './folder';
export {default as IconFork} from './fork';
export {default as Icon} from './icon';
export {default as IconGitHub} from './github';
export {default as IconInstagram} from './instagram';
export {default as IconLinkedin} from './linkedin';
export {default as IconLoader} from './loader';
export {default as IconLogo} from './logo';
export {default as IconPlayStore} from './playstore';
export {default as IconStar} from './star';
export {default as IconTwitter} from './twitter';
