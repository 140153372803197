export {default as Head} from './head';
export {default as Layout} from './layout';
export {default as Loader} from './loader';
export {default as Nav} from './nav';
export {default as Menu} from './menu';
export {default as Side} from './side';
export {default as Social} from './social';
export {default as Email} from './email';
export {default as Footer} from './footer';
export {default as Hero} from './sections/hero';
export {default as About} from './sections/about';
export {default as Jobs} from './sections/jobs';
export {default as Featured} from './sections/featured';
export {default as Projects} from './sections/projects';
export {default as Contact} from './sections/contact';
